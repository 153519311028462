<template lang='pug'>
  .bg-white.rounded-lg.px-4.py-2(style='width: 500px;')
    .text-xl.font-bold Выберите статус и нажмите "добавить"

    .flex.flex-col.gap-2.mt-4
      template(v-for='value in statuses')
        button(@click='choosed = value' :class='choosed == value ? "bg-green-500 text-white"  : ""') {{ value }}
    
    .mt-4
      button.bg-green-500.text-white(@click='save()') Добавить
      button.pl-2(@click='discard()') Отменить
</template>

<script>
export default {
  props: {
    onSave: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      choosed: 'Обработка',

      statuses: [
        'Обработка',
        'Оплачено',
        'Упаковано',
        'Отправлено',
        'Доставлено'
      ]
    }
  },
  methods: {
    save() {
      this.onSave(this.choosed);
      
      this.$root.closeDialog();
    },
    discard() {
      this.$root.closeDialog();
    }
  }
}
</script>

<style>

</style>